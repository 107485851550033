const Projects = () => {
    return (
        <main>
            <h2><span className="ald">*</span> Personal Projects</h2>
            <div className="wraps">
                <div className="proj">
                    <div className="pn">00</div>
                    <h4>
                        <a href="https://sociaslink.netlify.app/" target="_blank" rel="noreferrer">
                            Arcadia <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
                <div className="proj">
                    <div className="pn">01</div>
                    <h4>
                        <a href="https://doveme.netlify.app/" target="_blank" rel="noreferrer">
                            dove.me <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
                <div className="proj">
                    <div className="pn">02</div>
                    <h4>
                        <a href="https://light-bringers.netlify.app/" target="_blank" rel="noreferrer">
                            Light Bringers Club <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
                <div className="proj">
                    <div className="pn">03</div>
                    <h4>
                        <a href="https://danielradosa.com/nymui/" target="_blank" rel="noreferrer">
                            NymUI <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
                <div className="proj">
                    <div className="pn">04</div>
                    <h4>
                        <a href="https://danielradosa.com/rozpoctar/" target="_blank" rel="noreferrer">
                            Rozpočtár <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
                <div className="proj">
                    <div className="pn">05</div>
                    <h4>
                        <a href="https://danielradosa.com/tea_study.html" target="_blank" rel="noreferrer">
                            Tea study <i className='bx bx-link-external'></i>
                        </a>
                    </h4>
                    <p>...</p>
                </div>
            </div>
        </main>
    );
};

export default Projects;
