import { useState, useEffect } from "react";
import { useLanguage } from "../components/LanguageContext";

const ThemeSwitcher = () => {
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "7star");

    const { language, translations } = useLanguage();

    const translatedText = translations[language];


    useEffect(() => {
        let themeLink = document.getElementById("theme-stylesheet");

        if (!themeLink) {
            themeLink = document.createElement("link");
            themeLink.id = "theme-stylesheet";
            themeLink.rel = "stylesheet";
            document.head.appendChild(themeLink);
        }

        themeLink.href = `../themes/${theme}.css`;
    }, [theme]);

    const handleThemeChange = (event) => {
        const selectedTheme = event.target.value;
        setTheme(selectedTheme);
        localStorage.setItem("theme", selectedTheme);
    };

    return (
        <div className="settings">
            <label htmlFor="theme-select">{translatedText.optionstheme}:</label>
            <select
                id="theme-select"
                value={theme}
                onChange={handleThemeChange}
                style={{ marginLeft: "5px" }}
            >
                <optgroup label="Crypto">
                    <option value="7star">7STAR</option>
                </optgroup>
                <optgroup label="Media">
                    <option value="hellokitty">DEVON AOKI</option>
                </optgroup>
                <optgroup label="Style">
                    <option value="sony">DORFIC</option>
                    <option value="frutiger">FRUTIGER AERO</option>
                </optgroup>
            </select>
        </div>
    );
};

export default ThemeSwitcher;
