import { useLanguage } from "../components/LanguageContext";

const LanguageSwitcher = () => {
    const { language, handleLanguageChange, translations } = useLanguage();

    const translatedText = translations[language];

    return (
        <div className="settings">
            <label htmlFor="language-select">{translatedText.optionslang}:</label>
            <select
                id="language-select"
                value={language}
                onChange={(e) => handleLanguageChange(e.target.value)}
                style={{ marginLeft: "5px" }}
            >
                <optgroup label="All">
                    <option value="english">EN</option>
                    <option value="slovak">SK</option>
                    <option value="korean">KR</option>
                </optgroup>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
