import React, { createContext, useState, useContext } from "react";
import translations from "../translations/translations";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem("language") || "english");

    const handleLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        localStorage.setItem("language", selectedLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, handleLanguageChange, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
