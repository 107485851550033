export const images3D = [
    '../assets/3d/dr_ring.webp',
    '../assets/3d/grudsatr.webp',
    '../assets/3d/HOLYshieieet.webp',
    '../assets/3d/icePeaky.webp',
    '../assets/3d/planeSADGI.webp',
    '../assets/3d/render.gif',
    '../assets/3d/WOAMNA.webp',
    '../assets/3d/wttt2.webp',
];

export const imagesCovers = [
    '../assets/covers/albuCOver.webp',
    '../assets/covers/clear.webp',
    '../assets/covers/crank-1.webp',
    '../assets/covers/damn.webp',
    '../assets/covers/favorite.webp',
    '../assets/covers/gleb.webp',
    '../assets/covers/noise.webp',
    '../assets/covers/rnadom.webp',
    '../assets/covers/smile.webp',
];

export const imagesFast = [
    '../assets/fast/arotia.webp',
    '../assets/fast/baddesigner.webp',
    '../assets/fast/buterbrod.webp',
    '../assets/fast/golden.webp',
    '../assets/fast/isaiah.webp',
    '../assets/fast/meme5.webp',
    '../assets/fast/ritual.webp',
    '../assets/fast/road.webp',
    '../assets/fast/shit.webp',
    '../assets/fast/shop.webp',
    '../assets/fast/soap.webp',
    '../assets/fast/stuff.webp',
    '../assets/fast/toilet.webp',
];

export const imagesOther = [
    '../assets/other/burn2.webp',
    '../assets/other/colors.webp',
    '../assets/other/colorxlight.webp',
    '../assets/other/gravity.webp',
    '../assets/other/sa_dizajn.webp',
    '../assets/other/semsx.webp',
    '../assets/other/tempus.webp',
    '../assets/other/shadow.webp',
    '../assets/other/shape.webp',
    '../assets/other/sol-poster.webp',
    '../assets/other/soulwork.webp',
];