import { useLanguage } from "../components/LanguageContext";

const Home = () => {
    const { language, translations } = useLanguage();

    const translatedText = translations[language];

    return (
        <main>
            <h2><span className="ald">*</span> {translatedText.greeting}</h2>
            <p>
                {translatedText.about} <br />
                {translatedText.process}
            </p>
        </main>
    );
};

export default Home;
