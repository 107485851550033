import { useState } from "react";
import { Link } from "react-router-dom";
import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";
import { useLanguage } from "../components/LanguageContext";

const Navigation = () => {
    const { language, translations } = useLanguage();
    const translatedText = translations[language];
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <header>
            <h1>
                Daniel <span>Radosa</span>
            </h1>
            <div className="overlay"></div>

            <div className="options">
                <ThemeSwitcher />
                <LanguageSwitcher />
            </div>

            <nav>
                <button className="menu" onClick={toggleMenu}>
                    <i className="bx bx-menu"></i>
                </button>

                <div className={`menu-overlay ${menuOpen ? "open" : ""}`}>
                    <button className="close-menu" onClick={toggleMenu}>
                        <i className="bx bx-x"></i>
                    </button>
                    <ul>
                        <li><Link to="/" onClick={toggleMenu}><i className="bx bx-home-alt-2"></i> {translatedText.menuitem1}</Link></li>
                        <li><Link to="/blog" onClick={toggleMenu}><i className="bx bx-edit-alt"></i> {translatedText.menuitem2}</Link></li>
                        <li><Link to="/collabs" onClick={toggleMenu}><i className="bx bx-briefcase"></i> {translatedText.menuitem3}</Link></li>
                        <li><Link to="/projects" onClick={toggleMenu}><i className="bx bx-scatter-chart"></i> {translatedText.menuitem4}</Link></li>
                        <li><Link to="/graphic-design" onClick={toggleMenu}><i className="bx bx-spray-can"></i> {translatedText.menuitem5}</Link></li>
                        <li><Link to="/about-me" onClick={toggleMenu}><i className="bx bx-face"></i> {translatedText.menuitem6}</Link></li>
                        <li><Link to="/contact" onClick={toggleMenu}><i className="bx bx-phone"></i> {translatedText.menuitem7}</Link></li>
                        <li><Link to="/extra" onClick={toggleMenu}><i className="bx bx-dots-horizontal"></i> {translatedText.menuitem8}</Link></li>
                    </ul>
                </div>

                <div>
                    <Link to="/"><i className="bx bx-home-alt-2"></i> {translatedText.menuitem1}</Link> <span className="sep">|</span>
                    <Link to="/blog"><i className="bx bx-edit-alt"></i> {translatedText.menuitem2}</Link> <span className="sep">|</span>
                    <Link to="/collabs"><i className="bx bx-briefcase"></i> {translatedText.menuitem3}</Link> <span className="sep">|</span>
                    <Link to="/projects"><i className="bx bx-scatter-chart"></i> {translatedText.menuitem4}</Link> <span className="sep">|</span>
                    <Link to="/graphic-design"><i className="bx bx-spray-can"></i> {translatedText.menuitem5}</Link> <span className="sep">|</span>
                    <Link to="/about-me"><i className="bx bx-face"></i> {translatedText.menuitem6}</Link> <span className="sep">|</span>
                    <Link to="/contact"><i className="bx bx-phone"></i> {translatedText.menuitem7}</Link> <span className="sep">|</span>
                    <Link to="/extra"><i className="bx bx-dots-horizontal"></i> {translatedText.menuitem8}</Link>
                </div>
            </nav>
        </header>
    );
};

export default Navigation;
