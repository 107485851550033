import { useLanguage } from "../components/LanguageContext";

const About = () => {
    const { language, translations } = useLanguage();

    const translatedText = translations[language];

    return (
        <main>
            <h2><span className="ald">*</span> {translatedText.aboutheading}</h2>
            <ul>
                <li><b>{translatedText.aboutusername}:</b> Dango</li>
                <li><b>{translatedText.aboutlevel}:</b> 25</li>
                <li><b>{translatedText.aboutspecialty}:</b> {translatedText.aboutspecialtyvalue}</li>
            </ul>
            <h3 class="crim">Experience</h3>
            <p>
                <b>December 2024 - Now</b> <br /> Full-stack Developer @ esoul.systems &mdash; remote <i>Bratislava, Slovakia &mdash; 4 months</i> <br />
                <br />
                <b>May 2023 - November 2024</b> <br /> Junior Frontend Developer @ C.G.C. a.s. &mdash; hybrid <i>Bratislava, Slovakia &mdash; 1 year 7 months</i> <br />
                <br />
                <b>August 2022 - October 2022</b> <br /> Frontend Developer (Intern) @ HABSIDA &mdash; remote <i>Seoul, South Korea &mdash; 3 months</i>
            </p>
            <h3 class="crim">Studies</h3>
            <p>
                <b>2021 - 2023</b> <br /> Web Development @ EASV &mdash; daily <i>Esbjerg, DK</i> <br /> <br />
                <b>2019 - 2021</b> <br /> Multimedia design & communication @ EASV &mdash; daily <i>Esbjerg, DK</i> <br /> <br />
                <b>2015 - 2019</b> <br /> Graphic of digital media @ SOŠ &mdash; daily <i>Handlová, SK</i>
            </p>
            <h3 class="crim">My tech-stack</h3>
            <div class="techstack">
                <p><b>WEB:</b> HTML & CSS</p>
                <p><b>FRONTEND:</b> JS, React, Vue & Quasar</p>
                <p><b>STYLING:</b> Tailwind, SASS</p>
                <p><b>BACKEND:</b> Express, MongoDB, Node.js</p>
                <p><b>TOOLS:</b> Git, Figma, Adobe CC, MS Office</p>
                <p><b>OS:</b> macOS, Windows, Arch Linux</p>
            </div>
        </main>
    )
}

export default About;