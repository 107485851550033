const Contact = () => {
    return (
        <main>
            <h2><span className="ald">*</span> Contact me directly</h2>
            <ul>
                <li><b>E-mail:</b> <a href="mailto:28@danielradosa.com">28@danielradosa.com</a></li>
                <li><b>Phone:</b> <a href="tel:+421905871519">+421 905 871 519</a></li>
            </ul>
            <div class="sns">
                <a href="https://github.com/danielradosa" target="_blank" rel="noreferrer"><i class="bx bxl-github"></i>GitHub</a>
                <a href="https://www.linkedin.com/in/danielradosa/" target="_blank" rel="noreferrer"><i class="bx bxl-linkedin"></i>LinkedIn</a>
                <a href="https://www.instagram.com/danielradosa/" target="_blank" rel="noreferrer"><i class="bx bxl-instagram"></i>Instagram</a>
            </div>
        </main>
    )
}

export default Contact;