import { Routes, Route } from "react-router";
import { Home, Blog, Collabs, Projects, Design, About, Contact, Extra } from "./pages/index"

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/collabs" element={<Collabs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/graphic-design" element={<Design />} />
        <Route path="/about-me" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/extra" element={<Extra />} />
      </Routes>
  );
}

export default App;
