const Extra = () => {
    return (
        <main>
            <h2><span className="ald">*</span> Extra thingies</h2>
            <h3 class="crim">VS Code themes</h3>
            <table>
                <thead>
                    <tr>
                        <th>Theme</th>
                        <th>Link</th>
                        <th>Style</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Cinnamoroll</td>
                        <td>
                            <a href="https://marketplace.visualstudio.com/items?itemName=dango.cinnamonroll-theme">download</a>
                        </td>
                        <td><em>light</em></td>
                    </tr>
                    <tr>
                        <td>Cinnamoroll</td>
                        <td>
                            <a href="https://marketplace.visualstudio.com/items?itemName=dango.cinnamoroll-dark-theme">download</a>
                        </td>
                        <td><em>dark</em></td>
                    </tr>
                    <tr>
                        <td>Magicorp</td>
                        <td>
                            <a href="https://marketplace.visualstudio.com/items?itemName=dango.magicorp-theme">download</a>
                        </td>
                        <td><em>dark</em></td>
                    </tr>
                </tbody>
            </table>
            <h3 class="crim">My setup and dot files</h3>
            <p class="conf">
                GitHub repo for my Arch configuration &mdash;&nbsp;
                <a href="https://github.com/danielradosa/dac" target="_blank" rel="noreferrer">
                    link
                </a>
            </p>
        </main>
    )
}

export default Extra;