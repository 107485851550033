const Blog = () => {
    return (
        <main>
            <h2><span className="ald">*</span> Blog posts</h2>
            <h3 class="crim">20. March 2025 &mdash; 17:40</h3>
            <p>
                Language context complete. <br />
                Will translate the site bit by bit in free time. So be ware of the incomplete translations.
            </p>
            <h3 class="crim">19. January 2025 &mdash; 16:28</h3>
            <p>
                Themes functionality finished. <br />
                That's it for now. I will do language switcher next.
            </p>
            <h3 class="crim">31. December 2024 &mdash; 18:07</h3>
            <p>
                Aight, so apparently it's the last day of this year... <br />
                Good. Because as I am transitioning into new years, I took on a challenge. <br />
                A few actually: <br /> <br />
                <ul>
                    <li>continue learning latin</li>
                    <li>build some kind of web3 project</li>
                    <li>write more, especially on X or here on my blog</li>
                    <li>depend on AI less when coding (don't wanna be lazy and forget stuff)</li>
                </ul> <br />
                So, I wish you well reader &mdash; in the next year. Make sure to lock in and grind. <br />
                2025 has a lot to offer. Especially energetically. <br />
                It's a 9 year in numerology and 9 signifies end of a cycle. And you can either fall back to beginning, or transcend to a new level. <br />
                The choice is yours. Make it count.
            </p>
            <h3 class="crim">24. November 2024 &mdash; 17:45</h3>
            <p>
                Not minding previous post, I did switch my portfolio to a React project. <br />
                Simply because I'd like to separate my concerns, as I was copying the same code over and over way too much at this point. <br />
                For now however, I will implement a theme changer and then a translator. <br />
                The languages for now should be English, Slovak and Korean.
            </p>
            <h3 class="crim">11. August 2024 &mdash; 09:57</h3>
            <p>
                There's no need for SASS anymore since CSS now handles nesting and variables. <br />It also has the 'has' and 'not' selectors.
                So we can eliminate a lot of unnecessary JavaScript on the page for conditions to change styles, etc... <br />
                Recently, I’ve stopped using unnecessary technologies unless my websites/apps actually need them. <br /> <br />
                Even this portfolio used to be just HTML, CSS, and a tiny bit of JavaScript.
                If needed, I’ll add more, but for now, I don’t see a reason to. <br />
                It drives me crazy when someone uses something like Next and TypeScript for a portfolio. Seriously, why?... <br />
                Experience in a job proves more about a developer than some over-engineered portfolio created just to show <br />
                that they can throw 2435 technologies together with `npm install`.
            </p>
        </main>
    )
}

export default Blog;