import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Navigation, Footer } from './components';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router';
import { LanguageProvider } from "./components/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
        <div class="vignette"></div>
        <Navigation />
        <App />
        <Footer />
      </BrowserRouter>
    </LanguageProvider>
  </React.StrictMode>
);

reportWebVitals();
