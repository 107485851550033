const translations = {
	english: {
		menuitem1: "Home",
		menuitem2: "Blog",
		menuitem3: "Collabs",
		menuitem4: "Personal Projects",
		menuitem5: "Graphic Design",
		menuitem6: "About me",
		menuitem7: "Contact",
		menuitem8: "Extra",
		greeting: "Hey, how are you?",
		about: "I am working mainly with web, however graphic design & 3D is my cup of tea too.",
		process: "In it for the process, the result is what I appreciate afterwards.",
		aboutheading: "Info about me",
		aboutusername: "Username",
		aboutlevel: "Level",
		aboutspecialty: "Specialty",
		aboutspecialtyvalue: "digital alchemist",
		optionstheme: "Theme",
		optionslang: "Language"
	},
	slovak: {
		menuitem1: "Domov",
		menuitem2: "Blog",
		menuitem3: "Spolupráce",
		menuitem4: "Osobné Projekty",
		menuitem5: "Grafický Dizajn",
		menuitem6: "O mne",
		menuitem7: "Kontakt",
		menuitem8: "Extra",
		greeting: "Ahoj, ako sa máš?",
		about: "Pracujem hlavne s webom, ale grafický dizajn a 3D sú tiež mojou šálkou čaju.",
		process: "Som v tom pre proces, výsledok oceňujem až potom.",
		aboutheading: "Info o mne",
		aboutusername: "Používateľské meno",
		aboutlevel: "Úroveň",
		aboutspecialty: "Špecializácia",
		aboutspecialtyvalue: "digitálna alchýmia",
		optionstheme: "Téma",
		optionslang: "Jazyk"
	},
	korean: {
		greeting: "안녕, 어떻게 지내?",
		about: "나는 주로 웹 작업을 하지만 그래픽 디자인과 3D도 좋아해.",
		process: "과정을 즐기고, 결과는 나중에 감사하게 여긴다."
	}
};

export default translations;
