const Collabs = () => {
    return (
        <main>
            <h2><span className="ald">*</span> Collabs and commissions</h2>
            <div class="jb">
                <div class="l">
                    <a href="https://rentalservis.sk" target="_blank" rel="noreferrer">
                        <img src="https://rentalservis.sk/assets/RS_logo.png" alt="jbpic" />
                    </a>
                </div>
                <div class="r">
                    <h4>RENTAL servis, s.r.o.</h4>
                    <p>Redesign and development.</p>
                </div>
            </div>
        </main>
    )
}

export default Collabs;