import { images3D, imagesCovers, imagesFast, imagesOther } from '../images';

const Design = () => {

    return (
        <main>
            <h2><span className="ald">*</span> My graphic design works</h2>
            <h3 class="crim">3D</h3>
            <div class="design">
                {images3D.map((url, index) => (
                    <img key={index} src={url} className="designImage" alt="boob" />
                ))}
            </div>

            <h3 className="crim">COVERS</h3>
            <div className="design">
                {imagesCovers.map((url, index) => (
                    <img key={index} src={url} className="designImage" alt="boob" />
                ))}
            </div>

            <h3 className="crim">VISUAL FAST FOOD</h3>
            <div className="design">
                {imagesFast.map((url, index) => (
                    <img key={index} src={url} className="designImage" alt="boob" />
                ))}
            </div>

            <h3 className="crim">OTHER</h3>
            <div className="design">
                {imagesOther.map((url, index) => (
                    <img key={index} src={url} className="designImage" alt="boob" />
                ))}
            </div>
        </main>
    )
}

export default Design;